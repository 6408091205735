// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.font-dancing-script {
    font-family: "SuisseIntl";
    color: var(--Main-colors-White, #FFF);
/* Text styles - mobile/Text-button */
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px; /* 100% */
  }


.button-style-not-pressd
{
    width: 360px;
    height: 50px;
    flex-shrink: 0; 
    border-radius: 14px;
    border: 1px solid var(--Coffeemania-Orange, #F3BAB2);
    color: var(--Coffeemania-Orange,#F3BAB2);
    text-align: center;
    font-family: "SuisseIntl";
    font-size: 15px;
    font-style: normal;
    font-weight: 900;
    line-height: normal; 
    background-color: #FFF;

}

.button-style-pressd
{
    width: 360px;
    height: 50px;
    border-radius: 14px;
    background: var(--Coffeemania-Orange, #EC4C41); 
    color: var(--White, #FFF);
    text-align: center;
    font-family: "SuisseIntl";
    font-size: 15px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    border: 1px solid var(--Coffeemania-Orange, #EC4C41);

}`, "",{"version":3,"sources":["webpack://./src/components/buttonSend/RevSend.css"],"names":[],"mappings":";AACA;IACI,yBAAyB;IACzB,qCAAqC;AACzC,qCAAqC;IACjC,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB,EAAE,SAAS;EAC9B;;;AAGF;;IAEI,YAAY;IACZ,YAAY;IACZ,cAAc;IACd,mBAAmB;IACnB,oDAAoD;IACpD,wCAAwC;IACxC,kBAAkB;IAClB,yBAAyB;IACzB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,sBAAsB;;AAE1B;;AAEA;;IAEI,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,8CAA8C;IAC9C,yBAAyB;IACzB,kBAAkB;IAClB,yBAAyB;IACzB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,oDAAoD;;AAExD","sourcesContent":["\r\n.font-dancing-script {\r\n    font-family: \"SuisseIntl\";\r\n    color: var(--Main-colors-White, #FFF);\r\n/* Text styles - mobile/Text-button */\r\n    font-size: 15px;\r\n    font-style: normal;\r\n    font-weight: 500;\r\n    line-height: 15px; /* 100% */\r\n  }\r\n\r\n\r\n.button-style-not-pressd\r\n{\r\n    width: 360px;\r\n    height: 50px;\r\n    flex-shrink: 0; \r\n    border-radius: 14px;\r\n    border: 1px solid var(--Coffeemania-Orange, #F3BAB2);\r\n    color: var(--Coffeemania-Orange,#F3BAB2);\r\n    text-align: center;\r\n    font-family: \"SuisseIntl\";\r\n    font-size: 15px;\r\n    font-style: normal;\r\n    font-weight: 900;\r\n    line-height: normal; \r\n    background-color: #FFF;\r\n\r\n}\r\n\r\n.button-style-pressd\r\n{\r\n    width: 360px;\r\n    height: 50px;\r\n    border-radius: 14px;\r\n    background: var(--Coffeemania-Orange, #EC4C41); \r\n    color: var(--White, #FFF);\r\n    text-align: center;\r\n    font-family: \"SuisseIntl\";\r\n    font-size: 15px;\r\n    font-style: normal;\r\n    font-weight: 900;\r\n    line-height: normal;\r\n    border: 1px solid var(--Coffeemania-Orange, #EC4C41);\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
