// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainStyle
{
    display: flex;
    width: 335px;
    padding: 14px 12px;
    align-items: flex-start;
    font-family: 'SuisseIntl';
    font-size: 14px; 
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    font-feature-settings: 'clig' off, 'liga' off; 
    gap: 10px; 
    border-radius: 14px;
    border: 1px solid var(--Stroke, #DEDEDE);
    resize: none;
}

.textInput
{
    color: #393939;
    text-align: center;
    font-family: 'SuisseIntl';
    font-size: 16px;
    font-style: normal;
    font-weight: 400px;
    line-height: 21px; /* 143.75% */ 
    width: 335px; 
}`, "",{"version":3,"sources":["webpack://./src/components/InputComponent/InputComp.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,uBAAuB;IACvB,yBAAyB;IACzB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,6CAA6C;IAC7C,SAAS;IACT,mBAAmB;IACnB,wCAAwC;IACxC,YAAY;AAChB;;AAEA;;IAEI,cAAc;IACd,kBAAkB;IAClB,yBAAyB;IACzB,eAAe;IACf,kBAAkB;IAClB,kBAAkB;IAClB,iBAAiB,EAAE,YAAY;IAC/B,YAAY;AAChB","sourcesContent":[".mainStyle\r\n{\r\n    display: flex;\r\n    width: 335px;\r\n    padding: 14px 12px;\r\n    align-items: flex-start;\r\n    font-family: 'SuisseIntl';\r\n    font-size: 14px; \r\n    font-style: normal;\r\n    font-weight: 400;\r\n    line-height: 18px;\r\n    font-feature-settings: 'clig' off, 'liga' off; \r\n    gap: 10px; \r\n    border-radius: 14px;\r\n    border: 1px solid var(--Stroke, #DEDEDE);\r\n    resize: none;\r\n}\r\n\r\n.textInput\r\n{\r\n    color: #393939;\r\n    text-align: center;\r\n    font-family: 'SuisseIntl';\r\n    font-size: 16px;\r\n    font-style: normal;\r\n    font-weight: 400px;\r\n    line-height: 21px; /* 143.75% */ \r\n    width: 335px; \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
