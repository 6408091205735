import React, { useState, useRef, useEffect } from "react";
import './InputComp.css';
import { useDispatch, useSelector } from "react-redux";


function InputComp()
{

    const [val, setVal] = useState("");
    const textAreaRef = useRef(null);

    const dispatch = useDispatch();
    const rev = useSelector(state => state.star);

    const addCash = (rev) => 
    {
        dispatch({type: "ADD_REV", payload: val})
    }

    useEffect(() => {
        textAreaRef.current.style.height = "auto";
        textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
    }, [val]);

    const onChange = e => {
        setVal(e.target.value);
        addCash();
    };

    return(
        <div>
        <div className='textInput'>
          Расскажите, что вам не понравилось — так мы сможем стать лучше: 
        </div>
            <div>
                <h1></h1>
            </div>
            <textarea o className="mainStyle" placeholder="Комментарий" 
            rows="1"
            onChange={onChange}
            value={val}
            ref={textAreaRef}></textarea>
        </div>
    );
}

export default InputComp;