import React from "react";
import './modalEnd.css';
import elipse from './Ellipse 5.svg';
import vector from './Vector 4.svg'

const Modal = ({active}) =>
{

    let textModal = "Спасибо, ваши отзывы помогают ";
    let textModal1 = "нам стать лучше для вас!"

    return (
            <div className="modal">
                <div className="modal__content">
                    <div className="modal__img">
                    <img src={elipse}/>
                        <div className="model__vector">
                            <img src={vector}/>
                        </div>
                    </div>
                    <div className="modal__text">
                        {textModal}
                        <br/>
                        {textModal1}
                    </div>
                    <div className="modal__text">
            
                </div>
            </div>
        </div>
            );

}

export default Modal;
