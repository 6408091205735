import './App.css';
import React, {useState, useEffect} from 'react';
import RevSend from './components/buttonSend/RevSend';
import Modal from './components/modal/modalEnd';
import Stars from './components/stars/stars';
import MainText from './components/MainText/MainText';
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import url from './components/static/GlobalBag';
import token from './components/static/Token';
import headerImg from './components/modal/headerImage.svg';

const host = url();
const path = '/api/Reviews/GetDepNameByNumber';
const pathGetUrl = '/api/Reviews/PostUrlServiceMap';
const rToken = token(); 

function App(props) {
  
  const dispatch = useDispatch()
  const stars = useSelector(state => state.star);
  const rev = useSelector(state => state.rev);
  const dep = useSelector(state => stars.depNum); 
  const sendRev = useSelector(state => state.sendRev);
  let [response, setResponse] = useState("");
  let [postResponse, setPostResponse] = useState("");
  let [postResponseSourceName, setPostResponseSourceName] = useState("");

  const addCash = (dep) => 
  {
      dispatch({type: "ADD_DEPNUMBER", payload: props.depNumber})
  }

  let depName = "";
  let rPath = path + '?number=' + props.depNumber;

  useEffect(()=>
  { const fetch = async () =>{
      try
      {
        axios.defaults.headers.get['Access-Control-Allow-Methods'] = '*';
        axios.defaults.headers.get['Access-Control-Allow-Headers'] = '*';
        axios.defaults.headers.get['Access-Control-Allow-Credentials'] = 'true';
        axios.defaults.headers.get['Authorization'] = rToken;

        await axios.get(host + rPath).then((mainResponse)=> 
        {        
          let name = mainResponse.data.depName;
          setResponse(name);
        })
        .catch();
      }       
      catch(e)
      {
      }
    }
    fetch();
    addCash();
  }, []);

    let body = {
      "depId": props.depNumber
    }

  useEffect(() =>
  { async function PostRequest()
    {
      try
      {
          axios.defaults.headers.post['Access-Control-Allow-Methods'] = '*';
          axios.defaults.headers.post['Access-Control-Allow-Headers'] = '*';
          axios.defaults.headers.post['Access-Control-Allow-Credentials'] = 'true';
          axios.defaults.headers.post['Authorization'] = rToken;

          let mainResponse = await axios.post(host + pathGetUrl, body);
          setPostResponse(mainResponse.data.urlMap);
          setPostResponseSourceName(mainResponse.data.nameServiceMap);
      }       
      catch{}
    }

    PostRequest()
})
  depName = response;
  let sourceUrl = postResponseSourceName;

  console.log(sendRev);

  let res = "";

  if(sendRev == false)
  {
  res =  <div className='App'>
              <div className='header' >
                <div className='headerSvgStyle'>
                  <img src={headerImg}></img>
                </div>
                <h1 className='post'>Оставьте отзыв</h1>
              </div>
              <div className='textBox'>
                <MainText depName={depName}></MainText>
                <Stars urlMap={postResponse} sorceUrlMap={postResponseSourceName}></Stars>
                <RevSend sign = {props.sign}></RevSend> 
              </div>
            </div>
  }
  else
  {
    res = <div className='App'> 
                <div className='textBox'>
                <Modal></Modal>
              </div>
              </div>
  }

  return ( 
    res
  );
}

export default App;
