import React, { useState } from "react";
import './RevSend.css';
import Modal from "../modal/modalEnd";
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import url from '../static/GlobalBag'

const host = url();
const path = '/api/Reviews/ReviewSend';
const token = process.env.REACT_APP_TOKEN;


function RevSend(props)
{
    const [state, setStatusClic] = useState(false);
    const dispatch = useDispatch();
    const rev = useSelector(state => state.rev);
    const stars = useSelector(state => state.star);
    const dep = useSelector(state => state.depNum);

    let [response, setResponse] = useState("");
    
    let body = {
        "stars": stars,
        "textReview": rev,
        "restId": dep,
        "sign": props.sign
    };

    async function GetRequest()
    {
        try
        {
            axios.defaults.headers.post['Access-Control-Allow-Methods'] = '*';
            axios.defaults.headers.post['Access-Control-Allow-Headers'] = '*';
            axios.defaults.headers.post['Access-Control-Allow-Credentials'] = 'true';
            axios.defaults.headers.post['Authorization'] = token;
            let mainResponse = await axios.post(host + path, body);
            setResponse(mainResponse.data.urlMap);
            console.log(response);
        }       
        catch{}
    }


    function click()
    {
        setStatusClic(true);
        GetRequest();
    }

    if(stars == 0)
    {
        return(
            <div>
                <button className="button-style-not-pressd" disabled = {true}>Отправить</button>
            </div>);
    }
    if(state == true)
    {
        return(
             <div>             
                <Modal></Modal>
                <button className="button-style-pressd">Отправить</button>
            </div>

             );
    }
    else if(state == false)
    {
   
                return(
             <div>
                <button className="button-style-pressd" onClick={click} >Отправить</button>
             </div>

             );
    }
}

export default RevSend;