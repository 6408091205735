// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/PFAgoraSlabPro-Black.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/SuisseIntl-Book.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'FAgoraSlabPro';
    src: local('FAgoraSlabPro'),
      url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
  }

@font-face {
    font-family: 'SuisseIntl';
      src: local('SuisseIntl'),
        url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
  }

.font-dancing-script {
    font-family: "FAgoraSlabPro";
  }

.App
{
  User-select: none;
  margin-top: 5%;
  align-items: center;
  width: auto;
  height: auto;
  overflow-x:hidden
}

.post
{
  color: #393939;
  text-align: center;
  font-family: "FAgoraSlabPro";
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px; 
}

.textBox
{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}


.buttonSend
{
  position: relative;
  top: 50px;
}

.stars
{
  gap: 20px; 
  align-self: center;
  align-items: center;
}

.header
{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: -36px;
}

.headerSvgStyle
{
  display: flex;
flex-direction: column;
align-items: center;
width: 135px;
height: 106px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B;gEAC4D;EAC9D;;AAEF;IACI,yBAAyB;MACvB;kEACuD;EAC3D;;AAEF;IACI,4BAA4B;EAC9B;;AAEF;;EAEE,iBAAiB;EACjB,cAAc;EACd,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ;AACF;;AAEA;;EAEE,cAAc;EACd,kBAAkB;EAClB,4BAA4B;EAC5B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;;EAEE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;AACX;;;AAGA;;EAEE,kBAAkB;EAClB,SAAS;AACX;;AAEA;;EAEE,SAAS;EACT,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;;EAEE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,UAAU;AACZ;;AAEA;;EAEE,aAAa;AACf,sBAAsB;AACtB,mBAAmB;AACnB,YAAY;AACZ,aAAa;AACb","sourcesContent":["@font-face {\r\n    font-family: 'FAgoraSlabPro';\r\n    src: local('FAgoraSlabPro'),\r\n      url('./fonts/PFAgoraSlabPro-Black.ttf') format('truetype');\r\n  }\r\n\r\n@font-face {\r\n    font-family: 'SuisseIntl';\r\n      src: local('SuisseIntl'),\r\n        url('./fonts/SuisseIntl-Book.otf') format('truetype');\r\n  }\r\n\r\n.font-dancing-script {\r\n    font-family: \"FAgoraSlabPro\";\r\n  }\r\n\r\n.App\r\n{\r\n  User-select: none;\r\n  margin-top: 5%;\r\n  align-items: center;\r\n  width: auto;\r\n  height: auto;\r\n  overflow-x:hidden\r\n}\r\n\r\n.post\r\n{\r\n  color: #393939;\r\n  text-align: center;\r\n  font-family: \"FAgoraSlabPro\";\r\n  font-size: 28px;\r\n  font-style: normal;\r\n  font-weight: 500;\r\n  line-height: 38px; \r\n}\r\n\r\n.textBox\r\n{\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  gap: 24px;\r\n}\r\n\r\n\r\n.buttonSend\r\n{\r\n  position: relative;\r\n  top: 50px;\r\n}\r\n\r\n.stars\r\n{\r\n  gap: 20px; \r\n  align-self: center;\r\n  align-items: center;\r\n}\r\n\r\n.header\r\n{\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  gap: -36px;\r\n}\r\n\r\n.headerSvgStyle\r\n{\r\n  display: flex;\r\nflex-direction: column;\r\nalign-items: center;\r\nwidth: 135px;\r\nheight: 106px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
